import { template as template_313f535e40aa4285b797855b9ed8e7e1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_313f535e40aa4285b797855b9ed8e7e1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
