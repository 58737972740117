import { template as template_7854b171ca854784bdce15495c8d3b87 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7854b171ca854784bdce15495c8d3b87(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
