import { template as template_be41af6e0f3248fd998ef3bf93fd744b } from "@ember/template-compiler";
const WelcomeHeader = template_be41af6e0f3248fd998ef3bf93fd744b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
