import { template as template_214d52eba69f42a28184d71a130a6235 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_214d52eba69f42a28184d71a130a6235(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
